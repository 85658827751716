// palette
$main-background-color: rgb(232, 236, 236);
$secondary-background-color: #ecd8bf;
$main-color: #023c40;
$secondary-color: #683d00;

* {
  padding: 0;
  margin:0;
  box-sizing: border-box;
  // background-color: $main-background-color;
}

// position
@mixin column-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin column-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

@mixin column-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
}

@mixin row-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin row-left {
  display: flex;
  justify-content: center;
  align-items: center;
}


// todo moveme
.timeline-container {
  //position: absolute;
  //margin: 100px;
  border: 1px solid #000;

  > .container {
    //position: relative;
  }

  //width: 80vw;
  //height: 10px;
}

.timeline-canvas {

  //padding: 10px;

  canvas {
    height: 100vh;
    width: 100vw;
  }
}

.timeline-modal {
  z-index: 99;

  img {
    //padding: 10vw;
    width: 100%;
    height: 100%;
    padding: 5px;
    //max-height: 50vh;
  }
}

.App {
  min-height: 100vh;
}



.text1-container {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  padding: 5px;
}

.text1 {
  color: white;

}

.text2-container {
  position: absolute;
  z-index: 999;
  bottom: 0;
  right: 0;
  padding: 5px;

}
.text2 {
  color: white;
  a {
    color: white;
    text-decoration: none;
  }
}

.text3-container {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  padding: 5px;

}
.text4-container {
  position: absolute;
  z-index: 999;
  bottom: 0;
  left: 0;
  padding: 5px;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;
}

.text4 {
  display:flex;
}

.text3 {
  color: white;
  //a {
  //  color: white;
  //  text-decoration: none;
  //}
}

// todo check that probably don't need important
#simple-modal-description {
  flex: 1 0;
  display: block !important;
  //overflow-y: scroll !important;
  //max-height: 20vh !important;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

.underlined {
  text-decoration: underline;
}

// todo still seems to be a height issue on mobile (click logo at bottom and scroll

.audioURLInput {
  width: 80%;
}
.addressInput {
  width: 80%;
}

.spinner {
  height: 15px;
  width: 15px;
}

.closeModalButton {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top:5px;
}

.error {
  color: red;
}

.minitext {
  font-size: 10px;
}

.greyFont {
  color: #333;
}
